const buildTooltip = (mouseEnterEvent) => {
  const triggerEl = mouseEnterEvent.target;

  if (triggerEl.getAttribute("data-toggle") !== "cr-tooltip") return;

  const tooltipContent =
    triggerEl.getAttribute("data-content") || triggerEl.getAttribute("title");
  if (!tooltipContent) {
    console.warn(
      "[cr-components.initTooltips] data-content attribute missing for tooltip trigger."
    );
    return;
  }

  let tooltipPosition = triggerEl.getAttribute("data-placement");
  if (!tooltipPosition) {
    console.warn(
      '[cr-components.initTooltips] data-placement attribute missing for tooltip trigger. Assuming "top" as default.'
    );
    tooltipPosition = "top";
  }

  const tooltipEl = document.createElement("div");
  tooltipEl.classList.add("cr-tooltip");
  tooltipEl.classList.add("cr-tooltip-" + tooltipPosition);
  tooltipEl.classList.add("text-small");
  tooltipEl.innerHTML = tooltipContent;

  tooltipEl.style.position = "fixed";
  triggerEl.parentNode.appendChild(tooltipEl);

  tooltipEl.style.width = tooltipEl.offsetWidth + "px";

  const rect = triggerEl.getBoundingClientRect();

  switch (tooltipPosition) {
    case "top":
    case "bottom":
      tooltipEl.style.left = rect.x + triggerEl.offsetWidth / 2 + "px";
      break;
    case "left":
    case "right":
      tooltipEl.style.top = rect.y + triggerEl.offsetHeight / 2 + "px";
      break;
  }

  switch (tooltipPosition) {
    case "top":
      tooltipEl.style.top =
        "calc(" + (rect.y - tooltipEl.offsetHeight) + "px - .5rem)";
      break;
    case "bottom":
      tooltipEl.style.top =
        "calc(" + (rect.y + triggerEl.offsetHeight) + "px + .5rem)";
      break;
    case "left":
      tooltipEl.style.left =
        "calc(" + (rect.x - tooltipEl.offsetWidth) + "px - .5rem)";
      break;
    case "right":
      tooltipEl.style.left =
        "calc(" + (rect.x + triggerEl.offsetWidth) + "px + .5rem)";
      break;
  }

  const onTriggerMouseLeave = () => {
    triggerEl.parentNode.removeChild(tooltipEl);
    triggerEl.removeEventListener("mouseleave", onTriggerMouseLeave);
  };

  triggerEl.addEventListener("mouseleave", onTriggerMouseLeave);
};

export const initTooltip = (triggerEl) => {
  if (triggerEl.getAttribute("data-was-processed") === "true") return;

  triggerEl.addEventListener("mouseenter", buildTooltip);
  triggerEl.setAttribute("data-was-processed", "true");
};

export const initTooltips = () => {
  const tooltipTriggers = document.querySelectorAll(
    '[data-toggle="cr-tooltip"]'
  );

  for (const triggerEl of tooltipTriggers) {
    initTooltip(triggerEl);
  }
};

export const enableTooltip = (triggerEl) => {
  triggerEl.setAttribute("data-toggle", "cr-tooltip");
  initTooltip(triggerEl);
};

export const disableTooltip = (triggerEl) => {
  triggerEl.removeAttribute("data-toggle");
  triggerEl.removeAttribute("data-was-processed");
  triggerEl.removeEventListener("mouseenter", buildTooltip);
};
