import Vue from "vue";

function resource(path, actions = {}) {
  return Object.assign(
    {
      get: (id, params = {}, config = {}) =>
        Vue.axios.api.get(`${path}/${id}`, { params, ...config }),
      save: (obj, params = {}, config = {}) =>
        Vue.axios.api.post(path, obj, { params, ...config }),
      query: (params = {}, config = {}) =>
        Vue.axios.api.get(path, { params, ...config }),
      update: (id, obj, params = {}, config = {}) =>
        Vue.axios.api.put(`${path}/${id}`, obj, { params, ...config }),
      delete: (id) => Vue.axios.api.delete(`${path}/${id}`),
    },
    actions
  );
}
//region Login
export const loginService = {
  login: (obj) => Vue.axios.api.post(`/auth/login/partner`, obj),
  resetEmail: (obj) =>
    Vue.axios.api.post(`/auth/login/partner/reset_mail`, obj),
  resetPassword: (obj, config = {}) =>
    Vue.axios.api.post(`/auth/login/partner/reset`, obj, { config }),
  me: () => Vue.axios.api.get(`/auth/me`),
};
//endregion

//region Partner Comissions
export const commissionsService = {
  getComissions: (params) =>
    Vue.axios.api.get(`/partner_commission`, { params }),
  getStatsOrders: (params) =>
    Vue.axios.api.get(`/partner_commission/stats_orders`, { params }),
  getStats: (params) =>
    Vue.axios.api.get(`/partner_commission/stats`, { params }),
  getAffiliateBalance: (params) =>
    Vue.axios.api.get(`/partner_commission/affiliate_balance`, { params }),
};
//endregion

//region Partner Payments
export const paymentsService = {
  updatePayment: (id, params) =>
    Vue.axios.api.put(`/partner_payment/${id}`, {}, {params}),
  uploadFile: (id, obj, params = {}, config = {}) =>
    Vue.axios.api.post(`/partner_payment/${id}/upload`, obj, {
      params,
      ...config,
    }),
  paymentRequest: (obj, params = {}, config = {}) =>
    Vue.axios.api.post(`/partner_payment`, obj, {
      params,
      ...config,
    }),
  getPayments: (params) => Vue.axios.api.get(`/partner_payment`, { params }),
};
//endregion

//region Partner
export const partnerService =  {
  updateAvatar: (id, obj, params = {}, config = {}) =>
    Vue.axios.api.post(`/partner/${id}/avatar`, obj, { params, ...config }),
  getPartners: (params) => Vue.axios.api.get(`/partner`, { params }),
  update: (id, obj, params = {}, config = {}) =>
    Vue.axios.api.put(`/partner/${id}`, obj, { params, ...config }),
};
//endregion

//region Partner Campaigns
export const partnerCampaignsService = {
  getCampaigns: (params) => Vue.axios.api.get(`/partner_campaigns`, { params }),
};
//endregion

//region Country
export const countryService = resource("/chicorei/countries");
//endregion
