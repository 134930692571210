import Vue from 'vue';

export const scrollToPosition = (x, y, animationTime, container) => {
    if (x === undefined || y === undefined) return;

    let startX, startY;

    if (!container) {
        startX =
            document.body.scrollLeft || document.documentElement.scrollLeft;
        startY = document.body.scrollTop || document.documentElement.scrollTop;
    } else {
        startX = container.scrollLeft;
        startY = container.scrollTop;
    }

    const isGoingRight = startX > x;
    const isGoingUp = startY > y;
    const diffX = Math.abs(startX - x);
    const diffY = Math.abs(startY - y);
    const startTime = new Date().getTime();

    const animation = () => {
        const elapse = new Date().getTime() - startTime;
        const percentage = Math.min(elapse / animationTime, 1);

        const scrollX = isGoingRight
            ? startX - diffX * percentage
            : startX + diffX * percentage;
        const scrollY = isGoingUp
            ? startY - diffY * percentage
            : startY + diffY * percentage;

        if (!container) {
            window.scrollTo(scrollX, scrollY);
        } else if (container.scrollTo) {
            container.scrollTo(scrollX, scrollY);
        } else {
            // Edge, Safari
            container.scrollLeft = isGoingRight
                ? startX - diffX * percentage
                : startX + diffX * percentage;
            container.scrollTop = isGoingUp
                ? startY - diffY * percentage
                : startY + diffY * percentage;
        }

        if (elapse < animationTime) window.requestAnimationFrame(animation);
    };

    window.requestAnimationFrame(animation);
};

export const getDeepProp = (obj, path) => {
    if (path.indexOf('.') > 0) {
        const propertyChain = path.split('.');

        let propertyValue = { ...obj };
        while (propertyValue && propertyChain.length > 0) {
            propertyValue = propertyValue[propertyChain.shift()];
        }

        return propertyValue;
    } else {
        return obj[path];
    }
};

// normaliza, remove acentos, remove caracteres espeicais, coloca em minúsculas
// útil pra links e comparações em buscas
export const getSimplifiedText = (text) =>
    !text
        ? ''
        : text
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .replace(/[^\w\s]/gi, '');

export const compareText = (query, target, partialComparisson = true) => {
    if (partialComparisson) {
        return getSimplifiedText(target).indexOf(query) === 0;
    } else {
        return getSimplifiedText(query) === getSimplifiedText(target);
    }
};

export const getPropComparator = (propName, descending = false) => (
    first,
    second
) =>
    (getDeepProp(first, propName) > getDeepProp(second, propName) ? 1 : -1) *
    (descending ? -1 : 1);

export const EventBus = new Vue();

export const copyToClipboard = (text, showFeedback = true) => {
    const el = document.createElement('textarea');
    el.value = text;
    el.contentEditable = true;
    el.readOnly = true;
    el.style.position = 'absolute';
    el.style.left = '-9999px';

    document.body.appendChild(el);

    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
        const range = document.createRange();
        range.selectNodeContents(el);

        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        el.setSelectionRange(0, 999999);
    } else {
        el.select();
    }

    document.execCommand('copy');
    document.body.removeChild(el);

    if (showFeedback) {
        displayGrowlSuccess(
            'Copiado',
            'Texto disponível na área de transferência'
        );
    }
};

export const getColorContrast = (hex1, hex2) => {
    const hexToRGB = (h) => {
        let r = 0,
            g = 0,
            b = 0;

        // 3 digits
        if (h.length === 4) {
            r = '0x' + h[1] + h[1];
            g = '0x' + h[2] + h[2];
            b = '0x' + h[3] + h[3];

            // 6 digits
        } else if (h.length === 7) {
            r = '0x' + h[1] + h[2];
            g = '0x' + h[3] + h[4];
            b = '0x' + h[5] + h[6];
        }

        return [+r, +g, +b];
    };

    const luminance = (r, g, b) => {
        const a = [r, g, b].map(function (v) {
            v /= 255;
            return v <= 0.03928
                ? v / 12.92
                : Math.pow((v + 0.055) / 1.055, 2.4);
        });
        return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
    };

    const rgb1 = hexToRGB(hex1);
    const rgb2 = hexToRGB(hex2);
    const lum1 = luminance(rgb1[0], rgb1[1], rgb1[2]);
    const lum2 = luminance(rgb2[0], rgb2[1], rgb2[2]);
    const brightest = Math.max(lum1, lum2);
    const darkest = Math.min(lum1, lum2);
    return (brightest + 0.05) / (darkest + 0.05);
};
