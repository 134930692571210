import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Cleave from "vue-cleave-component";
import axios from "axios";
import VueAxios from "vue-axios";
import utils from "./services/utils";
import Tooltip from "./js/tooltip";

Vue.config.productionTip = false;
Vue.use(Cleave);
Tooltip(Vue);

let api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

api.interceptors.request.use(
  function (config) {
    config.headers = config.headers || {};

    const token = localStorage.getItem("AUTH_TOKEN");

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("AUTH_TOKEN");
      window.location.href = "/logout";
    }

    return Promise.reject(error);
  }
);

Vue.use(VueAxios, { api });

Vue.filter("formatPrice", (value) => utils.formatPrice(value));
Vue.filter("formatDate", (value, format) => utils.formatDate(value, format));

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
