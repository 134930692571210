<template>
  <nav class="menu" v-if="items">
    <ul>
      <template v-for="item of allowedSections">
        <li
          class="menu-parent"
          :class="{
            onlyaffiliate: item.onlyAffiliate,
            open:
              allowExpand &&
              (openedItem === item ||
                (!openedItem && item === activeSection) ||
                expandAll),
          }"
        >
          <router-link
            :to="item.link || '#'"
            @click.native="$emit('item-click', item)"
            :class="{ active: item === activeSection }"
          >
            <i
              class="menu-item-icon"
              :class="item.icon || 'fas fa-caret-right'"
            />
            <span>{{ item.title }}</span>
          </router-link>
          <ul
            v-if="item.children"
            class="menu-children"
            :class="{ active: item === activeSection }"
          >
            <template v-for="child of item.children">
              <li>
                <router-link
                  :to="child.link || '#'"
                  :class="{
                    active: child === activeItem,
                  }"
                >
                  <i class="fas fa-caret-right" />
                  <span>{{ child.title }}</span>
                </router-link>
              </li>
            </template>
          </ul>
        </li>
      </template>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "CrMenuList",

  props: {
    items: Array,
    openedItem: Object,
    allowExpand: Boolean,
    expandAll: Boolean,
  },

  data() {
    return {
      activeSection: null,
      activeItem: null,
    };
  },

  watch: {
    $route: {
      immediate: true,
      handler() {
        this.updateActiveItem();
      },
    },

    allowedSections: {
      immediate: true,
      handler() {
        this.updateActiveItem();
      },
    },
  },

  computed: {
    allowedSections() {
      let allowedSections = this.items.filter(this.isSectionAllowed);

      allowedSections = allowedSections.map((section) => ({
        ...section,
        children: section.children
          ? section.children.filter(this.isItemAllowed)
          : null,
      }));

      return allowedSections;
    },
  },

  methods: {
    isSectionAllowed(section) {
      return true;
    },

    isItemAllowed(item) {
      return true;
    },

    updateActiveItem() {
      if (this.$route.name) {
        for (const section of this.allowedSections) {
          if (this.$route.name.startsWith(section.name)) {
            this.activeSection = section;

            this.activeItem = section.children.find((i) =>
              this.$route.name.startsWith(i.name)
            );

            return;
          }
        }
      }

      this.activeSection = null;
      this.activeItem = null;
    },
  },
};
</script>
