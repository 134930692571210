// todo dúvida: dar query nos elementos toda hora ou só na inicialização?
// todo dúvida: é melhor ter métodos pra cada tipo de 'selector' ou apenas um método que identifica a melhor forma via regex?

// não fornece suporte pra "cadeias de seleção", como o código abaixo:
// cr$.byId('header').byClass('header-logo')
// pra obter o mesmo resultado, é melhor usar:
// cr$.queryOne('#header .header-logo')
// motivo: https://stackoverflow.com/questions/32430923/why-document-queryselector-is-more-efficient-than-element-queryselector

import { scrollToPosition } from "./common";

const isIterable = (obj) =>
  obj != null &&
  !(obj instanceof Element) &&
  typeof obj[Symbol.iterator] === "function";

const crQueryOperations = function (target) {
  // DOM element(s)
  this.raw = target;
};

crQueryOperations.prototype = {
  // Auxiliary function
  each(cb) {
    if (!this.exists()) return;

    if (isIterable(this.raw)) {
      for (const el of this.raw) cb(el);
    } else {
      return cb(this.raw);
    }
  },

  onlyFirst(cb) {
    if (!this.exists()) return;

    if (isIterable(this.raw)) {
      console.trace(
        `[cr$] avoid calling this function from multiple elements;`
      );
      return cb(this.raw[0]);
    } else {
      return cb(this.raw);
    }
  },

  length() {
    return !this.raw ? 0 : isIterable(this.raw) ? this.raw.length : 1;
  },

  exists() {
    return this.length() > 0;
  },

  on(eventName, listener) {
    return this.each((el) => el.addEventListener(eventName, listener));
  },

  // Shorthands
  click(handler) {
    if (handler !== undefined) {
      return this.on("click", handler);
    }

    this.each((el) => el.click());
    return this;
  },

  attr(name, value) {
    if (value !== undefined) {
      // setter
      this.each((el) => el.setAttribute(name, value));
      return this;
    } else {
      // getter
      return this.onlyFirst((el) => el.getAttribute(name));
    }
  },

  removeAttr(name) {
    this.each((el) => el.removeAttribute(name));
  },

  css(name, value) {
    if (value !== undefined) {
      // setter
      this.each((el) => (el.style[name] = value));
      return this;
    } else {
      // getter
      return this.onlyFirst((el) => window.getComputedStyle(el)[name]);
    }
  },

  html(content) {
    if (content !== undefined) {
      // setter
      this.each((el) => (el.innerHTML = content));
      return this;
    } else {
      // getter
      return this.onlyFirst((el) => el.innerHTML);
    }
  },

  computed(pseudo = null) {
    return this.onlyFirst((el) => window.getComputedStyle(el, pseudo));
  },

  remove() {
    this.each((el) => el.parentNode.removeChild(el));
    return this;
  },

  parent() {
    return this.onlyFirst((el) => cr$.byEl(el.parentElement));
  },

  children(selector) {
    return this.onlyFirst((el) =>
      cr$.byEl(selector ? el.querySelectorAll(selector) : el.children)
    );
  },

  append(element) {
    this.onlyFirst((el) => el.appendChild(element));
    return this;
  },

  toggle() {
    this.css("display") === "none" ? this.show() : this.hide();
  },

  show() {
    this.each((el) => {
      el.style.display = null;

      // alguns elementos podem conter regras CSS com 'display: none'
      if (window.getComputedStyle(el).display === "none") {
        el.style.display = "block";
      }
    });
    return this;
  },

  hide() {
    this.each((el) => (el.style.display = "none"));
    return this;
  },

  addClass(className) {
    this.each((el) => el.classList.add(className));
    return this;
  },

  removeClass(className) {
    this.each((el) => el.classList.remove(className));
    return this;
  },

  scrollTo(x, y, animationTime = 1000) {
    const el = this.onlyFirst((el) => el);

    if (!el) return;

    scrollToPosition(x, y, animationTime, el);
  },

  scrollIntoView(animationTime = 500) {
    const el = this.onlyFirst((el) => el);

    if (!el) return;

    const scrollY = Math.floor(
      el.getBoundingClientRect().top +
        (document.body.scrollTop || document.documentElement.scrollTop)
    );

    scrollToPosition(0, scrollY, animationTime);
  },
};

// pra criar novos métodos, adicionar aqui - crQueryOperations.prototype.method = funtion (...)

const getCrQueryOperations = function (selector, selectionFunction) {
  if (typeof selector !== "string") {
    return null;
  }

  const target = selectionFunction(selector);
  if (!target) {
    console.debug(`[cr$] "${selector}" not found`);
    return new crQueryOperations(null);
  } else {
    return new crQueryOperations(target);
  }
};

const cr$ = {
  byId(selector) {
    return getCrQueryOperations(
      selector,
      document.getElementById.bind(document)
    );
  },

  byTag(selector) {
    return getCrQueryOperations(
      selector,
      document.getElementsByTagName.bind(document)
    );
  },

  byClass(selector) {
    return getCrQueryOperations(
      selector,
      document.getElementsByClassName.bind(document)
    );
  },

  byEl(element) {
    return new crQueryOperations(element);
  },

  queryOne(selector) {
    return getCrQueryOperations(
      selector,
      document.querySelector.bind(document)
    );
  },

  queryAll(selector) {
    return getCrQueryOperations(
      selector,
      document.querySelectorAll.bind(document)
    );
  },

  ready(cb) {
    if (
      document.attachEvent
        ? document.readyState === "complete"
        : document.readyState !== "loading"
    ) {
      cb();
    } else {
      document.addEventListener("DOMContentLoaded", cb);
    }
  },
};

export default cr$;
