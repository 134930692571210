var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.items)?_c('nav',{staticClass:"menu"},[_c('ul',[_vm._l((_vm.allowedSections),function(item){return [_c('li',{staticClass:"menu-parent",class:{
          onlyaffiliate: item.onlyAffiliate,
          open:
            _vm.allowExpand &&
            (_vm.openedItem === item ||
              (!_vm.openedItem && item === _vm.activeSection) ||
              _vm.expandAll),
        }},[_c('router-link',{class:{ active: item === _vm.activeSection },attrs:{"to":item.link || '#'},nativeOn:{"click":function($event){return _vm.$emit('item-click', item)}}},[_c('i',{staticClass:"menu-item-icon",class:item.icon || 'fas fa-caret-right'}),_c('span',[_vm._v(_vm._s(item.title))])]),(item.children)?_c('ul',{staticClass:"menu-children",class:{ active: item === _vm.activeSection }},[_vm._l((item.children),function(child){return [_c('li',[_c('router-link',{class:{
                  active: child === _vm.activeItem,
                },attrs:{"to":child.link || '#'}},[_c('i',{staticClass:"fas fa-caret-right"}),_c('span',[_vm._v(_vm._s(child.title))])])],1)]})],2):_vm._e()],1)]})],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }