import moment from 'moment'

export default {
  version (date) {
    if (date.year() > 2019) {
      return 2
    }

    if (date.year() < 2019) {
      return 1
    }

    return date.month() < 7 ? 1 : 2
  },

  formatPrice (value) {
    if (!value) value = 0
    let val = (value / 1).toFixed(2).replace('.', ',')
    return 'R$' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  },

  formatDate (value, format) {
    if (!value) return ''
    return moment(value).locale('pt-BR').format(format)
  },

  now () {
    return moment().format('YYYY-MM-DD HH:mm:ss')
  }
}
