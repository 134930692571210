<template>
  <div
    id="menu-wrapper"
    :class="{
      open: isMenuOpen,
      'shadow-5': isMenuOpen || !fixedMenu,
      'menu-overlayed': !fixedMenu,
    }"
  >
    <div
      id="menu-overlay"
      v-if="isMenuOpen"
      class="pos-fixed z-1 pos-all bg-default-5"
      :class="{ 'hidden-md-up': fixedMenu }"
      @click="isMenuOpen = false"
    />
    <div
      id="menu-content"
      class="pos-relative z-2"
      @mouseenter="onMouseEnterMenu"
      @mouseleave="onMouseLeaveMenu"
    >
      <CrMenuList
        :items="items"
        :opened-item="openedItem"
        :allow-expand="isMenuOpen"
        @item-click="itemClicked"
      />
    </div>
  </div>
</template>

<script>
import TextField from "../../../../submodules/src/components/input/text-field/TextField.vue";
import cr$ from "../../../js/crquery";
import CrMenuList from "./CrMenuList";

export default {
  name: "CrMenu",

  components: { CrMenuList, TextField },

  props: {
    items: {
      type: Array,
      required: Boolean,
    },
  },

  data() {
    return {
      fixedMenu: false,

      isMenuOpen: false,

      openedItem: null,
    };
  },

  created() {
    if (this.fixedMenu) {
      cr$.byId("menu-toggle").removeClass("hidden-md-up");
      cr$.byId("menu-desktop-logo").removeClass("hidden-md-up");
      cr$.byId("main-content-wrapper").addClass("ml-0");
    }
  },

  mounted() {
    window.document
      .getElementById("menu-toggle")
      .addEventListener("click", this.toggleMenu);
  },

  watch: {
    isMenuOpen(to) {
      if (!to) this.openedItem = null;
    },
  },

  methods: {
    toggleMenu(ev) {
      if (ev) ev.stopPropagation();
      this.isMenuOpen = !this.isMenuOpen;
    },

    itemClicked(item) {
      if (item.children) {
        this.isMenuOpen = true;
        this.openedItem = this.openedItem === item ? null : item;
      }
    },

    onMouseEnterMenu() {
      if (!this.fixedMenu) this.isMenuOpen = true;
    },

    onMouseLeaveMenu() {
      if (!this.fixedMenu) this.isMenuOpen = false;
    },
  },
};
</script>
