import Vue from "vue";
import VueRouter from "vue-router";
import PanelView from "@/views/PanelView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/",
    name: "",
    component: PanelView,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("AUTH_TOKEN")) {
        next();
      } else {
        next("/login");
      }
    },
    children: [
      {
        path: "/",
        name: "Home",
        component: () =>
          import(
            /* webpackChunkName: "panel-home" */ "../views/panel/HomeView.vue"
          ),
      },
      {
        path: "/meu-perfil",
        name: "Profile",
        component: () =>
          import(
            /* webpackChunkName: "panel-profile" */ "../views/panel/ProfileView.vue"
          ),
      },
      {
        path: "/dados-bancarios",
        name: "Dados Bancários",
        component: () =>
          import(
            /* webpackChunkName: "panel-bank-account" */ "../views/panel/BankRegistrationView.vue"
          ),
      },
      {
        path: "/regras-de-uso",
        name: "Regras do Programa de Parceiros",
        component: () =>
          import(
            /* webpackChunkName: "panel-rules" */ "../views/panel/UsageRulesView.vue"
          ),
      },
      {
        path: "/pagamentos",
        name: "Pagamentos",
        component: () =>
          import(
            /* webpackChunkName: "panel-payments" */ "../views/panel/PaymentsView.vue"
          ),
      },
      {
        path: "/comissoes-confirmadas",
        name: "Comisões Confirmadas",
        component: () =>
          import(
            /* webpackChunkName: "panel-comissions" */ "../views/panel/ConfirmedCommissionsView.vue"
          ),
      },
      {
        path: "/comissoes-pendentes",
        name: "Comisões Pendentes",
        component: () =>
          import(
            /* webpackChunkName: "panel-comissions" */ "../views/panel/PendingCommissionsView.vue"
          ),
      },
      {
        path: "/campanhas",
        name: "Campanhas",
        component: () =>
          import(
            /* webpackChunkName: "panel-campaigns" */ "../views/panel/CampaignsView.vue"
          ),
      },
      // {
      //   path: "/central-de-ajuda",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "panel-help-center" */ "../views/panel/help-center/HelpCenterView.vue"
      //     ),
      //   children: [
      //     {
      //       path: "/",
      //       name: "Tópicos",
      //       component: () =>
      //         import(
      //           /* webpackChunkName: "panel-help-center-all-topics" */ "../views/panel/help-center/HelpCenterAllTopicsView.vue"
      //         ),
      //     },
      //     {
      //       path: "/:topicId(\\d+)",
      //       name: "view",
      //       component: () =>
      //         import(
      //           /* webpackChunkName: "panel-help-center-article" */ "../views/panel/help-center/ArticleView.vue"
      //         ),
      //       props: (route) => ({
      //         topicId: parseInt(route.params.topicId),
      //       }),
      //     },
      //   ],
      // },
      {
        path: "/logout",
        name: "Logout",
        beforeEnter: (to, from, next) => {
          localStorage.removeItem("AUTH_TOKEN");
          next("/login");
        },
      },
    ],
  },
  {
    path: "/login",
    name: "Entrar",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("AUTH_TOKEN")) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/recuperar-senha",
    name: "Recuperar Senha",
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ "../views/ForgotPasswordView.vue"
      ),
  },
  {
    path: "/redefinir-senha",
    name: "Redefinir Senha",
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ "../views/ResetPasswordView.vue"
      ),
    beforeEnter: (to, from, next) => {
      next();
    },
  },
  {
    path: "*",
    component: () =>
      import(/* webpackChunkName: "not-found" */ "../views/NotFoundView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
