import cr$ from "./crquery";
import { disableTooltip, initTooltip } from "./cr-components";

// Adiciona os atributos necessários para o tooltip
const putTooltipAttrs = (crTriggerEl, binding) => {
  crTriggerEl.attr("data-toggle", "cr-tooltip");
  crTriggerEl.attr("data-html", true); // Permite HTML
  crTriggerEl.attr("data-content", binding.value); // Usar data-content em vez de data-text
  crTriggerEl.attr("data-placement", binding.arg || "top");
};

const Tooltip = function (Vue) {
  Vue.directive("tooltip", {
    inserted: function (el, binding) {
      if (!binding.value) return;

      const crTriggerEl = cr$.byEl(el);
      putTooltipAttrs(crTriggerEl, binding);
      initTooltip(el); // Certifique-se de que isso suporte HTML
    },

    update: function (el, binding) {
      const crTriggerEl = cr$.byEl(el);

      if (!binding.value) {
        crTriggerEl.removeAttr("title");
        disableTooltip(el);
      } else {
        putTooltipAttrs(crTriggerEl, binding);
        initTooltip(el); // Inicializa novamente para aplicar mudanças
      }
    },
  });
};

export default Tooltip;
